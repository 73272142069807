export default {
  icon: ' mdi-package-variant-closed',
  text: 'Produtos',
  permissions: [
    { name: 'productsIndex', text: 'Visualizar Produtos', value: 0 },
    { name: 'productCreateEdit', text: 'Cadastrar/Editar Produtos', value: 0 },
    { name: 'productUpdatePrice', text: 'Atualizar Preço', value: 0 },
    { name: 'ProductsReportIndex', text: 'Relatório de Produtos', value: 0 },
    { name: 'ProductsByBrandReportIndex', text: 'Relatório de Produtos por Marca', value: 0 },

  ]
}